<template>
    <div style="background: #00802f12">
        <div class="header navbar navbar-default">
            <div class="logo">
                <router-link to="/"> <img :src="logo_url" alt="Logo" width="60"> </router-link>
            </div>
            <div class="bars"  @click="showSidebar">
                <i class="fas fa-bars"></i>
            </div>
            <div class="menu-bar">
                <div class="menu">
                    <a href="#">Home</a> 
                 </div>
                 <div class="menu">
                    <a href="#services">Services</a> 
                 </div>
                 <div class="menu">
                    <a href="#core-values">Core Values</a> 
                 </div>
                 <div class="menu">
                    <a href="#aboutus">About Us</a> 
                 </div>
                 <div class="menu">
                    <a href="#contactus">Contact Us</a> 
                 </div>
            </div>
            <div class="login-register">
                <span @click="register_modal = true"><i class="far fa-user"></i> Register</span> &nbsp; 
                <a href="https://app.belmazmcs.com.ng" target="_blank"><i class="fas fa-sign-in-alt"></i> Login</a>
            </div>
        </div>

        <nav id="sidebar" class="sidebar navbar navbar-expand-sm bg-light">
            <ul class="navbar-nav">
                <span class="close" @click="close">x</span>
              <li class="nav-item">
                <a class="nav-link" href="#services">Services</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#core-values">Core Values</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#aboutus">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contactus">Contact Us</a>
              </li>
            </ul>
        </nav>
        <transition name="fade" v-if="show">
            <div class="banner">
                <template>
                    <v-carousel 
                    cycle
                    hide-delimiters 
                    style="height: 500px;">
                      <v-carousel-item
                        v-for="(item,i) in items"
                        :key="i"
                        :src="item.src"
                      >

                      <div class="slider">
                        <div class="text-white">
                          {{ imageTitle[i] }}
                        </div>
                        <div class="sample-product-subtitle">
                            {{ imageSubTitle[i] }}
                        </div>
                      </div>
                    
                    </v-carousel-item>
                    </v-carousel>
                  </template>
            </div>
        </transition>
        <div class="content-services">
            <div class="content-header">
                <p id="services">Our Services</p> 
            </div>
            <div class="content-row">
                <div class="content-items">
                    <p><i class="fas fa-piggy-bank"></i><br>FIXED DEPOSIT</p>
                    <p class="items-text">
                        FIXED DEPOSIT
                        Belmaz MCS Limited recognizes the
                        importance of strategic investment in securing
                        one's financial future. The organisation provides
                        investment opportunities and advisory services
                        that cater to varying risk appetites and financial
                        objectives. Through careful analysis and expert
                        insights, Belmaz MCS Limited assists clients in
                        making informed investment decisions across
                        diverse asset classes.
                    </p>
                </div>
                <div class="content-items">
                    <p><i class="fas fa-coins"></i> <br>MEMBERSHIP LOAN</p>   
                    <p class="items-text">
                        Understanding the financial needs of its
                        customers, Belmaz MCS Limited offers
                        membership-based loan services. This
                        unique approach allows eligible members
                        to access financial assistance at favorable
                        terms. Whether for personal needs,
                        business ventures, or emergencies, the
                        membership loan service provides
                        individuals with a reliable source of
                        funds, fostering financial stability and
                        growth.
                    </p>       
                </div>
                <div class="content-items">
                    <p><i class="fas fa-wallet"></i> <br>SAVINGS WITH INTEREST</p>   
                    <p class="items-text">
                        Belmaz MCS Limited promotes a culture
                        of savings by offering accounts with
                        interest benefits. These accounts provide
                        members with a safe and secure platform
                        to save their money while earning
                        competitive interest rates which ranges
                        from 7% in six months to about 18% in
                        twelve month. By encouraging prudent
                        financial habits, the organisation
                        contributes to the financial
                        empowerment of its customers and helps
                        them achieve their long-term goals.
                    </p>       
                </div>
                <div class="content-items">
                    <p><i class="fas fa-leaf"></i><br>LEAD ORE MINING / AGRICULTURE </p>
                    <p class="items-text">
                        In recognition of the vital role that
                        agriculture plays in society, Belmaz MCS
                        Limited is actively involved in
                        agricultural initiatives and mining of lead ore. From sustainable
                        farming practices to innovative
                        cultivation techniques, the organisation
                        strives to enhance agricultural
                        productivity and contribute to food
                        security. By engaging in responsible and
                        eco-friendly agricultural practices,
                        Belmaz MCS Limited demonstrates its
                        commitment to the environment and the
                        well-being of local communities.
                    </p>
                </div>
            </div>
            <div class="content-row">
                <div class="content-items">
                    <p><i class="fas fa-egg"></i><br>FOOD AS MEDICINE</p>
                    <p class="items-text">
                        Belmaz MCS Limited recognizes the
                        importance of a healthy lifestyle and the
                        role that nutrition plays in maintaining
                        well-being. The organisation's "Food as
                        Medicine" service focuses on offering
                        nutritious, balanced, and therapeutic foods
                        that are tailored to address specific health
                        concerns. By blending culinary expertise
                        with medical insights, Belmaz MCS
                        Limited provides members with nourishing
                        options that promote health and healing. 
                    </p>
                </div>
                <div class="content-items">
                    <p><i class="fas fa-cart-plus"></i><br> SALES OF FOOD STUFF</p>
                    <p class="items-text">
                        As a provider of essential food items,
                        Belmaz MCS Limited ensures access to
                        high-quality foodstuffs for individuals
                        and families. The organisation's diverse
                        range of food products includes fresh
                        produce, packaged goods, and other
                        culinary necessities. By maintaining
                        strict quality standards and sourcing
                        products from reliable suppliers, Belmaz
                        MCS Limited contributes to the
                        sustenance and satisfaction of its
                        customers
                    </p>
                </div>
                <div class="content-items">
                    <p><i class="fas fa-drafting-compass"></i> <br>REAL ESTATE</p>   
                    <p class="items-text">
                        Belmaz MCS Limited extends its services
                        to the real estate sector, offering
                        opportunities for individuals and businesses
                        to acquire and invest in properties. With a
                        focus on transparency, integrity, and value,
                        the organisation assists members in finding
                        properties that match their preferences and
                        investment goals. Whether for residential or
                        commercial purposes, Belmaz MCS
                        Limited guides members through the
                        process, ensuring a smooth and informed
                        transaction 
                    </p>       
                </div>
                
            </div>

        </div>

        <div class="core-values">
            <div class="content-header">
                <p id="core-values">Core Values</p> 
            </div>
            <div class="core-values-contents">
                <div class="core-items">
                    <img src="../assets/integrity.png" alt="" >
                    <p>Integrity</p>
                    <span class="core-tooltips"> Belmaz MCS Limited upholds the highest standards of honesty and transparency in all its
                        dealings. The organisation believes in building lasting relationships with members, partners, and
                        stakeholders through ethical practices
                    </span>
                </div>
                <div class="core-items">
                    <img src="../assets/innovation.png" alt="" class="innovation">
                    <p>Innovation</p>
                    <span class="core-tooltips"> The organisation embraces innovation as a driving force behind its services. Belmaz MCS
                        Limited continuously seek new ways to improve and adapt its offerings, staying ahead of market trends
                        and customer preferences
                    </span>
                </div>
                <div class="core-items">
                    <img src="../assets/quality.png" alt="">
                    <p>Quality</p>
                    <span class="core-tooltips"> Delivering excellence is at the heart of Belmaz MCS Limited's operations. Whether in
                        providing nutritious foods, facilitating property transactions, or offering financial solutions, the
                        organisation is committed to maintaining top-tier quality standards.
                    </span>
                </div>
                <div class="core-items">
                    <img src="../assets/member-centric.png" alt="">
                    <p>Member-Centric</p>
                    <span class="core-tooltips"> Belmaz MCS Limited places its members' needs at the forefront. The organisation
                        takes a personalized approach to understand and address individual requirements, ensuring customer
                        satisfaction and loyalty
                    </span>
                </div>
            </div>
           
        </div>

        <div class="about">
            <div class="content-header">
                <p id="aboutus">About Us</p> 
            </div>
           <div class="about-banner">
            <div class="about-items">
                <p><i class="fas fa-exclamation-circle"></i><br>About</p>
                <p class="items-text">
                    Belmaz MCS Limited is a versatile and forward-thinking Co-operative society that has carved a niche for itself in multiple industries. With a
                    steadfast commitment to innovation, quality, and members satisfaction, the society has gained recognition as a trusted name in the market.
                </p>
            </div>
            <div class="about-items">
                <p><i class="far fa-lightbulb"></i><br>Our Vision</p>
                <p class="items-text">
                    Belmaz MCS Limited envisions itself as a dynamic and
                    influential player in industries that directly impact people's
                    lives. The organisation strives to be a leader in promoting
                    holistic wellness, responsible business practices, and
                    economic growth.. 
                </p>
            </div>
            <div class="about-items">
                <p><i class="fas fa-compass"></i><br>Core Mission</p>
                <p class="items-text">
                    The mission of Belmaz MCS Limited is to enhance the
                    well-being of individuals, families, and communities by
                    providing innovative solutions across various sectors.
                    Through its services, the organisation aims to contribute to
                    a healthier society, sustainable development, and financial
                    empowerment.
                </p>
            </div>
             <p>
            </p>
           </div>
        </div>
       
        <div class="content-footer">
            <div class="content-header">
                <p id="contactus">Contact Us</p> 
            </div>
            <p class="content-footer-text">Are you ready to embark on a journey of well-being, financial empowerment, and
                growth? Join the Belmaz MCS Limited community today with ELEVEN THOUSAND NAIRA
                (NGN 11,000.00)! Explore our wide range of services and experience the difference first
                hand.</p>
            <p class="connect" id="contact-section">Connect with us on social media or call/whatsapp us at<br>
                <i class="fas fa-phone"></i> {{ company_phone }} |  <i class="fas fa-envelope"></i> {{ company_email}}
            </p>
            <div class="social-media">
              <p> <i class="fab fa-facebook"></i></p>
              <p> <i class="fab fa-twitter"></i></p>
              <p> <i class="fab fa-youtube"></i></p>
              <p><a href="https://www.instagram.com/p/Cw-qmiiN1s5/?igshid=NjZiM2M3MzIxNA==" target="_blank"><i class="fab fa-instagram-square"></i></a></p>
            </div>
            <p class="copyright">© 2023 Belmaz Multipurpose Cooperative Limited. All Rights Reserved. </p>
        </div>
    
         <!-- Select Investment Modal -->
        <transition name="modal-fade">
            <div v-if="register_modal">
                <div class="modal-mask">
                    <div class="modal-wrapper col-md-5" style="margin:auto">
                        <div class="row">
                            <div class="col-md-1">
                                <a title="Close" class="modal-close-mobile" @click="closeMenu"> <i
                                    class="fa fa-times-circle" aria-hidden="true"></i></a>
                                <br>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                                    <p class="modal-logo">
                                    </p>
                                    <p class="text-black">
                                      Personal Information
                                    </p>
                                     <div>
                                        <input style="margin-top:4px" type="text" class="form-control input-control" placeholder="Enter firstname" v-model="firstname"  required>
                                        <input style="margin-top:4px" type="text" class="form-control input-control" placeholder="Enter middlename (Optional)" v-model="middlename" >
                                        <input style="margin-top:4px" type="text" class="form-control input-control" placeholder="Enter lastname" v-model="lastname"  required>

                                        <select style="margin-top:4px" class="form-control input-control" v-model="gender" required>
                                            <option value="">-Select -</option>
                                            <option value="1">Male</option>
                                            <option value="2">Female</option>
                                        </select>
                                        <input style="margin-top:4px" type="text" class="form-control input-control" placeholder="Enter phone" v-model="phone_number" required>
                                        <input style="margin-top:4px" type="text" class="form-control input-control" placeholder="Enter email" v-model="email_address"  required>
                                        <input style="margin-top:4px" type="text" class="form-control input-control" placeholder="Enter referral Id(Optional)" v-model="referral_id" >
                                        <input style="margin-top:4px" type="password" class="form-control input-control pwd" placeholder="Enter password" v-model="password"  required minlength="5" maxlength="12" @input="pwdCheck()">
                                        <input style="margin-top:4px" type="password" class="form-control input-control cpwd" placeholder="Confirm password" v-model="confirm_password"  required @input="cpwdCheck()">
                                        <div class="password-check">
                                            <span class="five-char">Password should be at least 5 characters</span> <br>
                                            <span class="uppercase">Password should contain upper case(e.g ABC)</span><br>
                                            <span class="number">Password should contain number (e.g 1234567890)</span><br>
                                            <span class="password-match"> Password and Confirm password must match</span><br>
                                        </div>
                                        <button class="form-control btn btn-success btn-block" v-if="!loading" @click="register"> Submit
                                        </button>
                                        <button class="form-control btn btn-success btn-block" type="button" disabled v-if="loading">
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>
                                    </div>
                                        <p>&nbsp;</p>
                                    
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div>
                                    <a href="#" title="Close" class="modal-close" @click="closeMenu"> <i
                                            class="fa fa-times-circle" aria-hidden="true"></i></a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </transition> 
</div>
</template>
<script>
import swal from 'sweetalert2';

export default {
    
    data() {
    return {
        logo_url: process.env.VUE_APP_API_LOGO,
        banner_url: process.env.VUE_APP_API_BANNER,
        company_phone: process.env.VUE_APP_COMPANY_PHONE,
        company_email: process.env.VUE_APP_COMPANY_EMAIL,
        company_name: process.env.VUE_APP_COMPANY,
        register_modal: false,
        loading: false,
        firstname: '',
        middlename: '',
        lastname: '',
        phone_number: '',
        email_address: '',
        referral_id: '',
        gender: '',
        password: '',
        confirm_password: '',
        show: false,
        title: 'Belmaz Multipurpose Cooperative Society',
        items: [
          {
            src: process.env.VUE_APP_API_BANNER,
          },
          {
            src: process.env.VUE_APP_API_BANNER2,
          },
          {
            src: process.env.VUE_APP_API_BANNER3,
          },
          {
            src: process.env.VUE_APP_API_BANNER4,
          },
          {
            src: process.env.VUE_APP_API_BANNER5,
          },
         
        ],
        imageTitle:[
          'Welcome to BMCS',
          'Be Part of Belmaz Business take over',
          'You Can Be Big',
          'Lets Chart 2024 Course Together',
          'Join the Free Training on our Telegram Group',
        ],
        imageSubTitle:[
          'We offer Savings, Pool, Loan etc.',
          'Join Us Today, Show the Progress Tomorrow',
          'To achieve success, this community, think Belmaz',
          'Be part of the community which gives you the opportunity to do business with what you have',
          'Lets help you walk 2024',
        ]
    };
  },

    beforeMount(){
       
    },
    mounted() {
        document.title = this.title
        this.showDiv()
    },
    created(){

    },

  methods: {
    showDiv() {
      this.show = true;
    },
    showSidebar() {
        var x = document.getElementById("sidebar");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    },
    close(){
        var x = document.getElementById("sidebar");
        if (x.style.display === "none") {
            x.style.display = "block";
        } else {
            x.style.display = "none";
        }
    },
    pwdCheck() {
            var length = this.password.length; // get password length

            if(length>=5) {
                var char = document.getElementsByClassName("five-char")
                char[0].style.color = "green";
                
                var bi = document.getElementsByClassName("btn-success")
                bi[0].disabled = false;
            }
            else {
                var char7 = document.getElementsByClassName("five-char")
                char7[0].style.color = "black";

                var bxx = document.getElementsByClassName("btn-success")
                bxx[0].disabled = true;
            }
            // check for uppercase
            if(this.containsUppercase(this.password) == true) {
                var char2 = document.getElementsByClassName("uppercase")
                char2[0].style.color = "green";

                var bx = document.getElementsByClassName("btn-success")
                bx[0].disabled = false;
            }
            else {
                var char6 = document.getElementsByClassName("uppercase")
                char6[0].style.color = "green";

                var r = document.getElementsByClassName("btn-success")
                r[0].disabled = true;
            }
    
            // check for numbers
            if(this.containsNumber(this.password) == true) {
                var char3 = document.getElementsByClassName("number")
                char3[0].style.color = "green";

                var v = document.getElementsByClassName("btn-success")
                v[0].disabled = false;
            }
            else {
                var char4 = document.getElementsByClassName("number")
                char4[0].style.color = "black";

                var s = document.getElementsByClassName("btn-success")
                s[0].disabled = true;
            }
            
    },
    cpwdCheck() {
    if(this.password == this.confirm_password) {
        var char3 = document.getElementsByClassName("cpwd")
        char3[0].style.color = "green";

        var cha = document.getElementsByClassName("pwd")
        cha[0].style.color = "green";

        var chars = document.getElementsByClassName("password-match")
        chars[0].style.color = "green";

    }
    else{
        var c = document.getElementsByClassName("cpwd")
        c[0].style.color = "red";

        var ch = document.getElementsByClassName("pwd")
        ch[0].style.color = "red";

        var charx = document.getElementsByClassName("password-match")
        charx[0].style.color = "red";
    }
    },
    containsUppercase(str) {
            return /[A-Z]/.test(str);
    },
    containsSpecialChars(str) {
        //eslint-disable-next-line
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    },
    containsNumber(str) {
            return /\d/.test(str);
    },
    closeMenu() {
        this.register_modal = false
    },

    async register(){
                try {
                                           
                    if (!this.firstname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter firstname',
                        })
                        return;
                    }                
                    if (!this.lastname) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter surname',
                        })
                        return;
                    }
                    if (!this.gender) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select gender',
                        })
                        return;
                    }
                    if (!this.email_address) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please select branch',
                        })
                        return;
                    }
                    if (!this.phone_number) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter phone',
                        })
                        return;
                    }
                    if (!this.password) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter password',
                        })
                        return;
                    }
                    if (!this.confirm_password) {
                        swal.fire({
                            icon: 'warning',
                            title: 'Error...',
                            width: 450,
                            height: 5,
                            text: 'Please enter confirm password',
                        })
                        return;
                    }
    
                    this.loading = true;
    
                    this.response = await this.$http.post('/register', {
                        firstname: this.firstname,
                        middlename: this.middlename,
                        surname: this.lastname,
                        gender: this.gender,
                        phone: this.phone_number,
                        email: this.email_address,
                        referral_id: this.referral_id,
                        password: this.password,
                        cpassword: this.confirm_password,
                    });
    
                    this.loading = false;
    
                    if (!this.response.data.success) {
                        swal.fire({
                                icon: 'error',
                                width:450,
                                height:5,
                                title: "Error",
                                text: this.response.data.message,
                            });
                            return;
    
                    } else {
    
                    swal.fire({
                        icon: 'success',
                        width:450,
                        height:5,
                        title: "Success",
                        text: this.response.data.message,
                        });
                    }
                    this.firstname = "",
                    this.middlename = ""
                    this.lastname = ""
                    this.gender = ""
                    this.phone_number = ""
                    this.email_address = ""
                    this.password = ""
                    this.confirm_password = ""
                    this.register_modal = false
    
                } catch (err) {
                    console.log(err);
                }
            },

  },
    
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;700&display=swap');
.modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 1.1s;
  }
  .modal-fade-enter, .modal-fade-leave-to /* .modal-fade-leave-active in <2.1.8 */ {
    opacity: 0.1;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
.header {
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    font-size: 10px;
    font-weight: 400;
    box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.3);
    color: #fff;
}
.sidebar{
    display: none;
}
.bars {
    display: none;
}
.menu-bar {
    display: flex; 
    flex-direction: row; 
    justify-content: space-between; 
    width:45%
}
.login-register{
    span {
        color: #00802f;
        cursor: pointer;
        font-size: 14px;
    }
    a {
        color: #00802f;
        font-size: 14px;
    }
}
.menu{
    font-size: 14px !important;
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0px 0px #000;
    font-family: Mulish;
}
.menu > a {
    color: #00802f;
}
.menu:hover {
    cursor: pointer;
}
.banner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: auto;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    //background: url(../assets/banner.png) no-repeat ;
    background-size: cover;
    color: #fff;
}
.slider {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //background-color:#00802f; 
    //opacity: 0.2;
    height: 500px; 
    font-size: 45px; 
    font-weight: bold;
    text-shadow: 0px 1px #000; 
    font-family: Mulish;
    text-align: center;
  }
.sample-product-subtitle{
    font-size: 20px !important; 
    text-shadow: 0px 0px #f5f9f711; 
    text-align: center;
    width: 50%;
    color: #fff;
    font-weight: 300 !important;
  }
.action-botton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.action-botton2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 40px;
    border-radius: 6px;
    font-weight: 800;
    font-size: 14px;
}
.content-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 30px;
    font-family: Mulish;
    font-size:18px;
    text-decoration: underline;
    color: #00802f;
    text-decoration-color: #00802f;
    text-decoration-thickness: 4px; 
    text-underline-offset: 10px;
    font-weight: 800;
    text-transform: uppercase;
  }
  .content-services{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
  }
  .content-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
  }
  .content-items{
    box-sizing: border-box;
    width: 260px;
    height: 370px;
    border: 1px solid #00802f;
    box-shadow: -1px -1px 4px #ccc;
    border-radius: 4px;
    margin: 10px;
    padding: 15px;
    text-align: center;
  
    p{
      font-family: Mulish;
      font-size:13px;
      font-weight: 700;

      .fa-piggy-bank{
        font-size:30px;
        color: #00802f;
      }
      .fa-wallet{
        font-size:30px;
        color: #00802f;
      }
      .fa-coins{
        font-size:30px;
        color: #00802f;
      }
      .fa-cart-plus{
        font-size:30px;
        color: #00802f;
      }
      .fa-drafting-compass{
        font-size:30px;
        color: #00802f;
      }
      .fa-egg{
        font-size:30px;
        color: #00802f;
      }
      .fa-leaf{
        font-size:30px;
        color: #00802f;
      }
    }
    .items-text {
      font-family: Mulish;
      font-size:13px;
      font-weight: 500;
      text-align: justify;
    }
  
  }
  .about{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: 500px;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background: url(../assets/about.png) no-repeat ;
    background-size: cover;
    opacity: 0.9;
    margin-top: 0px;
  }
  .about-banner{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  .about-items{
    box-sizing: border-box;
    width: 260px;
    height: 260px;
    border: 1px solid #00802f;
    box-shadow: -1px -1px 4px #ccc;
    background: #FFF;
    border-radius: 450px;
    margin: 10px;
    padding: 20px;
    text-align: center;
    
    p{
      font-family: Mulish;
      font-size:13px;
      font-weight: 700;

      .fa-compass{
        font-size:30px;
        color: #00802f;
      }
      .fa-exclamation-circle{
        font-size:30px;
        color: #00802f;
      }
      .fa-lightbulb{
        font-size:30px;
        color: #00802f;
      }
    }
    .items-text {
      font-family: Mulish;
      font-size:11px;
      font-weight: 700;
      text-align: center;
    }
  
  }
  .core-values{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    height: 250px;
    bottom: 0;
    font-family: Mulish;
    font-size: 10px;
    background: #FFF;
    margin-top: 0px;
  }
  .core-values-contents{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    text-align: center;
    width: 80%;
    margin-top: -30px;
    font-family: Mulish;
  }
  .core-items{
    width: 150px;
    height: 150px;
    border-radius: 47px;
    margin: 10px;

    img{
        width: 60%;
    }
    .innovation{
        width: 75% !important;
    }
    
    p{
      font-family: Mulish;
      font-size:15px;
      font-weight: 700;
      color: #00802f;
    }
    .core-tooltips{
        display: none;
    }

  }
  .core-items:hover {
    .core-tooltips{
        cursor: pointer;
        display: block;
        font-size: 10px;
        font-family: Mulish;
        margin-top: -130px;
        text-align:justify;
        background: #00802f;
        color: #FFF;
        border-radius: 4px;
        padding: 10px;
        width: 100%;
    }
  }

  .content-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    font-family: Mulish;
    font-size: 10px;
    background-size: cover;
    opacity: 0.5;
    bottom: 0;    
  }
  .content-footer-text{
    font-family: Mulish;
    font-size:13px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  .connect{
    font-family: Mulish;
    font-size:10px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  .social-media{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  
    p{
      font-size: 20px; 
      margin: 6px;
    }
  }
  .copyright{
    font-family: Mulish;
    font-size:10px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  .term-service{
    font-family: Mulish;
    font-size:10px;
    font-weight: 500;
    text-align: center;
    word-wrap: break-word;
    width: 40em; 
    color: #000;
  }
  
  .input-control {
    height: 30px;
    font-size: 12px;
    font-family: Mulish;
}
.password-check{
    margin-left:50px;
    font-size: 10px;
    font-family: Mulish;
    .five-char {
        color: black
    }
    .uppercase{
        color: black
    }
    .number{
        color: black
    }
    .special-char{
        color: black
    }
    .password-match{
        color: black
    }
}
  .modal-mask {
    position: fixed;
    //z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f9f711;
    display: table;
    transition: opacity .5s ease;

    &>div {
        width: 80%;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #00802f;
        background: #f2f3f2;
        backdrop-filter: blur(13px);
        border-radius: 10px;
        box-sizing: border-box;
        font-family: Mulish;
    }

    h3 {
        margin-top: 10px;
        font-size: 18px;
        color: #000;
        font-weight: bold;
        font-family: Mulish;
    }

    span {
        margin-bottom: 20px;
    }

}

.modal-close {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #fff;
    }
}
.modal-close-mobile {
    color: #000;
    line-height: 50px;
    font-size: 140%;
    position: absolute;
    right: 0;
    text-align: center;
    top: 2;
    width: 70px;
    text-decoration: none;

    &:hover {
        color: #FFF;
    }

    display: none;
}
@media (min-width:320px) and (max-width: 1200px) {
   
    .sidebar {
        width: auto;
        height: auto;
        position: fixed;
        z-index: 9998;
        transition: opacity .4s ease;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 0px;
        margin-left: 10px;

        a{
            color: #00802f;
        }
        .close{
            margin-left: 20px;
        }
      }
      .sidebar a {float: left;}
      div.content {margin-left: 0;}

    .logo{
        display: none;
    }
    .bars {
        font-size: 30px;
        display: block;
        cursor: pointer;
        color: #00802f;
    }
    .menu {
        display: none;
    }
    .banner{
        width: 100%;
    }
    .action-botton{
        margin-top: 50px;
    }
    .action-botton2{
        margin-top: 50px;
    }
    .content-services{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .content-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
      }
      .content-items{
        box-sizing: border-box;
        width: 100%;
      }
      .about-banner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 100%;
        
      }
      .about{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        height: 1000px;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: url(../assets/about.png) no-repeat ;
        background-size: cover;
        opacity: 0.9;
        margin-top: 0px;
      }

      .core-values{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        height: 750px;
        bottom: 0;
        font-family: Mulish;
        font-size: 10px;
        background: #FFF;
        margin-top: 0px;
      }
      .core-values-contents{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        text-align: center;
        width: 80%;
        margin-top: -30px;
        font-family: Mulish;
      }
      .core-items:hover {
        .core-tooltips{
            cursor: pointer;
            display: block;
            font-size: 10px;
            font-family: Mulish;
            margin-top: -130px;
            text-align:justify;
            background: #00802f;
            color: #FFF;
            border-radius: 4px;
            padding: 10px;
            width: 100%;
        }
      }
      .content-footer-text{
        font-family: Mulish;
        font-size:13px;
        font-weight: 500;
        text-align: center;
        word-wrap: break-word;
        width: 28em; 
        color: #000;
      }
      .social-media{
        display: flex;
        flex-direction: row;
        justify-content: center;
      
        p{
          font-size: 20px; 
        }
      }

    
      .modal-mask {
        position: fixed;
        //z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(244, 241, 241, 0.5);
        display: table;
        transition: opacity .4s ease;
    
        &>div {
            width: 80%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 0.5px solid #00802f;
            background: #f4f7f5;
            backdrop-filter: blur(13px);
            border-radius: 10px;
            box-sizing: border-box;
            font-family: Mulish;
        }
    
        h3 {
            margin-top: 10px;
            font-size: 18px;
            color: #000;
            font-weight: bold;
            font-family: Mulish;
        }
    
        span {
            margin-bottom: 20px;
        }
    
    }
    .modal-close {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: none;
    }
    .modal-close-mobile {
        color: #000;
        line-height: 50px;
        font-size: 140%;
        position: absolute;
        right: 0;
        text-align: center;
        top: 2;
        width: 70px;
        text-decoration: none;

        &:hover {
            color: #FFF;
        }

        display: block;
    }

}
</style>